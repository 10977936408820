import httpService, {baseURL} from "../../../services/httpService"
import {toast} from "react-toastify"
import {handleLogin, handleLogout, handleRequestCompleted} from "../../../redux/authentication"

export const login = (username, password, isDeviceLoginEnabled, history) => {
    console.log('data', { username, password, isDeviceLoginEnabled })
    return (dispatch) => {
        httpService
            ._post(`${baseURL}user/login`, { username, password, isDeviceLoginEnabled })
            .then(res => {
                dispatch(handleRequestCompleted(true))
                if (res.status === 200 && res.data.statusCode === 200) {
                    toast.success('Logged in Successfully')
                    const data = { ...res.data.data.userData, accessToken: res.data.data.accessToken}
                    dispatch(handleLogin(data))
                    history.replace('/dashboard')
                } else {
                    toast.error(res.data.message)
                }
            })
            .catch(err => {
                dispatch(handleRequestCompleted(true))
                toast.error(err.message)
            })
    }

}

export const unAuthorize = () => {
    return dispatch => {
        dispatch(handleLogout())
        /*dispatch(push('/login'))*/
        location.reload()  // (temporary) until find a solution to update route using redux
    }

}
