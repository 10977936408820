import {createAction} from '@reduxjs/toolkit'
const initialState = {
    data: {},
    activities: [],
    isLoading: false
}

export const setDashboardData = createAction("dashboardReducer/setDashboardData")
export const setActivities = createAction("dashboardReducer/setActivities")
export const setLoading = createAction("dashboardReducer/setLoading")

const dashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case setDashboardData.type:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            }
        case setActivities.type:
            return {
                ...state,
                activities: action.payload,
                isLoading: false
            }
        case setLoading.type:
            return {
                ...state,
                isLoading: action.payload
            }
        default:
            return state
    }
}

export default dashboardReducer
