// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import users from '../views/user/store/reducer'
import reports from '../views/report/store/reducer'
import dashboard from '../views/dashboard/store/reducer'

const rootReducer = {
  auth,
  navbar,
  layout,
  users,
  reports,
  dashboard
}

export default rootReducer
