import {createAction} from '@reduxjs/toolkit'
const initialState = {
    data: [],
    object: {},
    isEdit: false,
    isError: false,
    isSuccess: false,
    isLoading: false,
    isModalLoading: false
}

export const setUsers = createAction("userReducer/setUsers")
export const setLoading = createAction("userReducer/setLoading")
export const setModalLoading = createAction("userReducer/setModalLoading")
export const editUser = createAction("userReducer/editUser")
export const setUser = createAction("userReducer/setUser")
export const setError = createAction("userReducer/setError")
export const setSuccess = createAction("userReducer/setSuccess")
export const setEdit = createAction("userReducer/setEdit")

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case setUsers.type:
            return {
                ...state,
                data: action.payload.data,
                isEdit: false,
                isError: false,
                isSuccess: false,
                isLoading: false,
                isModalLoading: false
            }
        case setUser.type:
            return {
                ...state,
                object: action.payload.data,
                isLoading: false
            }
        case editUser.type:
            return {
                ...state,
                object: action.payload.data,
                isEdit: true,
                isModalLoading: false
            }
        case setLoading.type:
            return {
                ...state,
                isLoading: action.payload
            }
        case setModalLoading.type:
            return {
                ...state,
                isModalLoading: action.payload
            }
        case setError.type:
            return {
                ...state,
                isError: action.payload,
                isModalLoading: false
            }
        case setSuccess.type:
            return {
                ...state,
                isSuccess: action.payload
            }
        case setEdit.type:
            return {
                ...state,
                isEdit: action.payload
            }
        default:
            return state
    }
}

export default userReducer
